var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-card',{attrs:{"flat":""}},[_c('base-row',{attrs:{"no-gutters":""}},[_c('base-col',{attrs:{"cols":"4"}},[_c('base-date-picker',{attrs:{"label":"対象月","month":""},on:{"input":_vm.update},model:{value:(_vm.filter.targetMonth),callback:function ($$v) {_vm.$set(_vm.filter, "targetMonth", $$v)},expression:"filter.targetMonth"}})],1),_c('base-col',{attrs:{"cols":"4"}},[_c('sales-staff-select',{attrs:{"label":"自社営業担当","label-cols":"4","allow-no-select":"","no-select-label":"指定なし"},on:{"input":_vm.update},model:{value:(_vm.filter.sales),callback:function ($$v) {_vm.$set(_vm.filter, "sales", $$v)},expression:"filter.sales"}})],1)],1),(_vm.loaded)?_c('item-accordion-list-box',{attrs:{"items":_vm.contracts,"no-filter":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.item;
return [_c('base-container',{attrs:{"vertical-center":"","horizontal-space-between":""}},[_c('base-typography',{attrs:{"single":"","value":item.corp_name}}),_c('base-label',{staticClass:"mx-1",attrs:{"color":_vm.completedPurchaseOrderLabelColor(item)}},[_vm._v(" 注文書受領状況: "),_c('base-text',{staticClass:"mx-1",attrs:{"large":"","bold":""}},[_c('base-text-number',{attrs:{"value":item.completedPurchaseOrderCount}})],1),_vm._v(" / "),_c('base-text',{staticClass:"mx-1"},[_c('base-text-number',{attrs:{"value":item.contracts.length}})],1),_vm._v(" 件完了 ")],1)],1)]}},{key:"content",fn:function(ref){
var corpItem = ref.item;
return [_c('base-divider'),_c('base-typography',{attrs:{"value":"対象エンジニア"}}),_c('base-card',{attrs:{"flat":""}},[_vm._l((corpItem.contracts),function(item,index){return [_c('base-row',{key:item.contract_period_id,attrs:{"no-gutters":""}},[_c('base-col',{attrs:{"cols":"10"}},[_c('base-row',[_c('base-col',{attrs:{"cols":"1"}},[_c('user-kind-label',{attrs:{"value":item.user_kind}})],1),_c('base-col',{attrs:{"cols":"3"}},[_c('base-typography',{attrs:{"large":"","bold":"","single":"","value":((item.firstname) + " " + (item.lastname))}})],1),_c('base-col',{attrs:{"cols":"4"}},[_c('base-typography',{attrs:{"single":""}},[_vm._v(" 営業担当： "),_c('base-text',{attrs:{"large":"","value":((item.sales_firstname) + " " + (item.sales_lastname))}})],1)],1),_c('base-col',{attrs:{"cols":"4"}},[_c('base-typography',{attrs:{"single":""}},[_vm._v(" 取引先担当： "),_c('base-text',{attrs:{"large":"","value":((item.sales_corp_firstname) + " " + (item.sales_corp_lastname))}})],1)],1),_c('base-col',{attrs:{"offset":"4","cols":"8"}},[_c('base-typography',{attrs:{"single":""}},[_vm._v(" 契約期間： "),_c('base-text',{attrs:{"value":item.contract_begin.dateStrJp(),"large":""}}),_vm._v(" ～ "),_c('base-text',{attrs:{"value":item.contract_end.dateStrJp(),"large":""}})],1)],1)],1)],1),_c('base-col',{attrs:{"cols":"2"}},[_c('base-container',{attrs:{"direction-column":"","horizontal-center":"","vertical-center":""}},[(item.is_received_purchase_order === 1)?_c('base-label',{attrs:{"text":"注文書：受領済み","color":"success"}}):(item.is_received_purchase_order === 2)?_c('base-label',{attrs:{"text":"注文書：一部受領済み","color":"warning"}}):_c('base-label',{attrs:{"text":"注文書：未受領","color":"warning"}}),_c('base-button',{staticClass:"mt-3",attrs:{"label":"詳細","text":"","to":{
                      path: ("/contracts/" + (item.contract_period_id)),
                      query: { month: _vm.filter.targetMonth.monthStr() }
                    }}})],1)],1)],1),(index !== corpItem.contracts.length - 1)?_c('base-divider',{key:((item.contract_period_id) + "-d")}):_vm._e()]})],2)]}}],null,false,2833398903)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }